import { Component, OnInit } from "@angular/core";
import { TrackingReportService } from "../_services/tracking-report.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { environment } from "../../environments/environment";
import { UserService } from "../_services";

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
})
export class ReportComponent implements OnInit {
  error = false;
  message;
  repackDate;
  userSetDate;
  scanDate;
  boxingDate;
  userSetName;
  boxingName;
  repackName;
  user;
  destVal;
  users;
  status = null;
  public whId: number | undefined;
  public showList = false;
  public pslinq = false;
  public resCount = false;
  public editWeightform: FormGroup;
  public warehouse = [];
  public result: any = [];
  public parcels = [];
  public resultMessage: string | undefined;
  public totalCount = 0;
  public totalWeight = 0;
  public showLoading = false;
  public editLoading = false;
  public showDetails: any = {};
  public pahestId: number;
  public isObject = false;
  public noResult = false;
  public isChangeComment = false;
  public comment: string;
  public destinations = [
    { id: "", val: "All" },
    { id: 1, val: "Armenia" },
    { id: 2, val: "Russia" },
    { id: 4, val: "Amanati" },
    { id: 5, val: "Ukraine" },
    { id: 6, val: "SpaceCargo" },
    { id: 9, val: "Georgia" },
    { id: 10, val: "Kyrgyzstan" },
  ];
  public stats = [
    { id: "", val: "All" },
    { id: 1, val: "Scan" },
    { id: 2, val: "Repack" },
    { id: 4, val: "User Set" },
    // {id: 5, val: 'Box'},
  ];
  public statsForWhRus = [
    { id: "", val: "All" },
    { id: "scan_in_ru", val: "Scan" },
    { id: "ready_to_send", val: "Ready To Send" },
    { id: "courier", val: "Sent" },
  ];
  public reportForm: FormGroup;
  public cargoCode: string;
  public userCode: string;

  constructor(
    private trackingReport: TrackingReportService,
    private fb: FormBuilder,
    private _userService: UserService
  ) {
    this.reportForm = this.setControls();
  }

  ngOnInit() {
    this.user = localStorage.getItem("name");
    this.getWarehouses();
    this.getParceles();
    this.formBuilder();
    this.whId = +localStorage.getItem("detectUser");
    this._userService.getAll().subscribe((data) => {
      this.users = data.users;
    });
  }

  public handleStatus(status) {
    this.status = status.value;
    if (status.value === "") {
      this.reportForm.get("order.user_id").setValue(null);
    }
  }

  public search(): void {
    this.showLoading = true;
    this.noResult = false;
    this.showDetails = {};
    this.totalCount = 0;
    this.totalWeight = 0;
    this.trackingReport.searching(this.reportForm.value).subscribe(
      (data) => {
        if (this.whId === 9 && data.data.length > 0) {
          data.data = data.data.map((el) => {
            if (el.client_fullname || el.orderer_info) {
              const splittedName = (
                el.client_fullname || el.orderer_info
              ).split(" ");
              splittedName[splittedName.length - 1] = splittedName[
                splittedName.length - 1
              ]
                .replace("RU", "RGD")
                .toUpperCase();
              el.client_fullname = splittedName.join(" ");
            }
            return el;
          });
        }

        if (data.data.length || data.data.count) {
          this.pslinq = Array.isArray(data.data);
          this.showList = false;
          this.cargoCode = this.reportForm.value.order.belongs;
          this.totalCount = this.pslinq ? data.data.length : data.data.count;
          this.result = data.data;
          if (this.pslinq && this.result.length) {
            this.result.map(({ waight }) => {
              this.totalWeight += +waight;
            });
            this.totalWeight = +this.totalWeight.toFixed(2);
          } else {
            this.totalWeight = data.data.weight.toFixed(2);
          }

          this.resCount = true;
          this.showLoading = false;
          this.isObject =
            typeof data.data === "object" &&
            data.data !== null &&
            !Array.isArray(data.data)
              ? true
              : false;
        } else {
          this.noResult = true;
          this.showLoading = false;
        }
      },
      (err) => {
        console.error(err);
        this.showLoading = false;
      }
    );
  }

  export() {
    let data = this.reportForm.value;
    if (this.whId != 8) {
      window.open(
        `${environment.API_ENDPOINT_ROOT}/filter-usa-orders?tracking_code=${
          data.order.tracking_code ? data.order.tracking_code : ""
        }&warehouse=${data.warehouse}&user_id=${
          data.order.user_id ? data.order.user_id : ""
        }&user_full_name=${
          data.order.client_fullname ? data.order.client_fullname : ""
        }&destination=${data.order.belongs}&dispatch=${
          data.order.dispatch
        }&from=${data.order.created_at ? data.order.created_at : ""}&to=${
          data.order.created_at_end ? data.order.created_at_end : ""
        }&status=${data.order.status}&parcel_names=${
          data.parcel ? data.parcel : ""
        }&export=1`
      );
    } else {
      window.open(
        `${environment.API_ENDPOINT_ROOT}/filter-russian-orders?tracking_code=${
          data.order.tracking_code ? data.order.tracking_code : ""
        }&user_id=${
          data.order.user_id ? data.order.user_id : ""
        }&user_full_name=${
          data.order.client_fullname ? data.order.client_fullname : ""
        }&from=${data.order.created_at ? data.order.created_at : ""}&to=${
          data.order.created_at_end ? data.order.created_at_end : ""
        }&status=${data.order.status}&parcel_names=${
          data.parcel ? data.parcel : ""
        }&export=1`
      );
    }
    //
    // let myWindow = window.open('', 'PRINT');
    // myWindow.document.write(
    //     '<html><head><title>' + document.title + '</title>'
    // );
    // myWindow.document.write(
    //     '<style>table, th, td { border: 1px solid black; border-collapse: collapse;}</style>'
    // );
    // myWindow.document.write('</head><body >');
    // myWindow.document.write('<table style="width: 100%; text-align: center">');
    // myWindow.document.write(
    //     '<thead>\n' +
    //     '                        <tr>\n' +
    //     '                            <th>Scan Date</th>\n' +
    //     '                            <th>Tracking</th>\n' +
    //     '                            <th>Weight</th>\n' +
    //     '                            <th>Volume Weight</th>\n' +
    //     '                            <th>Comment</th>\n' +
    //     '                            <th>Box ID</th>\n' +
    //     '                            <th>Parcel</th>\n' +
    //     '                        </tr>\n' +
    //     '                        </thead>'
    // );
    // myWindow.document.write('<tbody>');
    // for (let el of data) {
    //     el.waight = el.waight == null ? '' : el.waight;
    //     el.v_waight = el.v_waight == null ? '' : el.v_waight;
    //     el.parcel_name = el.box ? el.box.parcel.name : '';
    //     myWindow.document.write(
    //         '<tr><td>' +
    //         el.created_at +
    //         '</td>' +
    //         '<td>' +
    //         el.tracking_code +
    //         '</td><td>' +
    //         el.waight +
    //         '</td><td>' +
    //         el.v_waight +
    //         '</td>' +
    //         '<td>' +
    //         el.comment +
    //         '</td><td>' +
    //         el.box_id +
    //         '</td><td>' +
    //         el.parcel_name +
    //         '</td></tr>'
    //     );
    // }
    // myWindow.document.write('</tbody>');
    // myWindow.document.write('</body></html>');
    //
    // myWindow.document.close(); // necessary for IE >= 10
    // myWindow.focus(); // necessary for IE >= 10*/

    return true;
  }

  show(result) {
    this.editLoading = true;
    this.userCode = result.user_code;
    this.trackingReport.showDetails({ id: result.id }).subscribe(
      (d) => {
        this.editLoading = false;
        if (d.orderDetails[0].created_at) {
          this.scanDate = new Date(d.orderDetails[0].created_at);
          this.scanDate.setHours(this.scanDate.getHours() - 5);
        }
        if (d.orderDetails[0].repack) {
          this.repackDate = new Date(d.orderDetails[0].repack.created_at);
          this.repackDate.setHours(this.repackDate.getHours() - 5);
          this.repackName = d.orderDetails[0].repack.user_name
            ? d.orderDetails[0].repack.user_name
            : "";
        }

        if (d.orderDetails[0].user_set) {
          this.userSetDate = new Date(d.orderDetails[0].user_set.created_at);
          this.userSetDate.setHours(this.userSetDate.getHours() - 5);
          this.userSetName = d.orderDetails[0].user_set.user_name
            ? d.orderDetails[0].user_set.user_name
            : "";
        }

        if (d.orderDetails[0].boxing) {
          this.boxingDate = new Date(d.orderDetails[0].boxing.created_at);
          this.boxingDate.setHours(this.boxingDate.getHours() - 5);
          this.boxingName = d.orderDetails[0].boxing.user_name
            ? d.orderDetails[0].boxing.user_name
            : "";
        }

        this.showDetails = d.orderDetails[0];
        this.comment = d.orderDetails[0].comment;
        this.editWeightform.patchValue({
          width: d.orderDetails[0].width,
          height: d.orderDetails[0].height,
          length_v: d.orderDetails[0].length,
          dispatch: d.orderDetails[0].dispatch,
          v_weight: d.orderDetails[0].v_waight,
          weight: d.orderDetails[0].waight,
        });
      },
      (e) => {
        console.log(e);
      }
    );
  }

  showImage(result) {
    if (result) {
      window.open(result.image_path, "_blank", "noreferrer");
    }
  }

  showReportAll(showDesc: number) {
    this.showLoading = true;
    this.trackingReport
      .showDataReportUsa(this.reportForm.value, showDesc)
      .subscribe(
        (data) => {
          if (this.whId === 9 && this.destVal == 2 && data.data.length > 0) {
            data.data = data.data.map((el) => {
              const splittedName = (
                el.client_fullname || el.orderer_info
              ).split(" ");
              splittedName[splittedName.length - 1] = splittedName[
                splittedName.length - 1
              ]
                .replace("RU", "RGD")
                .toUpperCase();
              el.client_fullname = splittedName.join(" ");
              return el;
            });
          }
          this.showList = true;
          this.cargoCode = this.reportForm.value.order.belongs;
          this.showLoading = false;
          this.result = data.data;
          if (this.result.length) {
            this.resultMessage = undefined;
          } else {
            this.resultMessage = "No Result";
            setTimeout(() => {
              this.resultMessage = undefined;
            }, 1500);
          }
        },
        (err) => {
          console.error(err);
          this.showLoading = false;
        }
      );
  }

  public editWeight(tracking_code, order_whight): void {
    const formData = {
      height: this.editWeightform.get("height").value,
      weight: this.editWeightform.get("weight").value,
      v_weight: this.editWeightform.get("v_weight").value,
      width: this.editWeightform.get("width").value,
      length: this.editWeightform.get("length_v").value,
      dispatch: this.editWeightform.get("dispatch").value,
      tracking_code: tracking_code,
    };
    this.trackingReport.editWeight(formData).subscribe((data: any) => {
      return `[Edit Weight] ${data}`;
    });
  }

  public calc(w, h, l): void {
    let vWeight;
    if (this.userCode && this.userCode.includes("ARM")) {
      vWeight = 5000;
    } else {
      vWeight = 6000;
    }
    let totalV = (w * h * l) / vWeight;
    this.editWeightform.patchValue({
      v_weight: totalV,
    });
  }

  public getParceles(): void {
    this.trackingReport.getParcels().subscribe((data: any) => {
      this.parcels = data.parcels;
    });
  }

  setDestVal(val) {
    this.destVal = val.value;
  }

  editComment() {
    this.isChangeComment = true;
  }

  handleCommentValue(comment) {
    this.comment = comment.value;
  }

  changeComment() {
    if (this.comment && this.showDetails.id) {
      const newData = {
        id: this.showDetails.id,
        comment: this.comment,
      };

      this.trackingReport.fetchChangeComment(newData).subscribe((d) => {
        if (d.status === 200) {
          this.comment = d.order.comment;
        }
      });
    }
    this.isChangeComment = false;
  }

  private formBuilder(): void {
    this.editWeightform = this.fb.group({
      editWeightValue: [null],
      width: [null],
      order: [null],
      height: [null],
      length_v: [null],
      dispatch: [1],
      v_weight: [null],
      weight: [null],
    });
  }

  private setControls(): FormGroup {
    const td = new Date();
    const months = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    let currentDay = td.getDate().toString();
    if (currentDay.length == 1) {
      currentDay = `0${currentDay}`;
    }
    const currentDate = `${td.getFullYear()}-${
      months[td.getMonth()]
    }-${currentDay}`;
    return this.fb.group({
      parcel: [null],
      warehouse: [null],
      order: this.fb.group({
        dispatch: [""],
        tracking_code: [null],
        services: [false],
        created_at: [null],
        created_at_end: [null],
        belongs: [""],
        client_fullname: [null],
        user_id: [""],
        status: [""],
      }),
    });
  }

  private getWarehouses(): void {
    this.trackingReport.getWarehousesForReport().subscribe(
      (d) => {
        this.pahestId = d.wh_id;
        if (d.status == 200) {
          this.warehouse = d.warehouse;
          this.reportForm.controls["warehouse"].setValue(this.pahestId);
        }
        if (d.warehouse[0].id === 7) {
          this.destinations = [
            { id: 1, val: "Armenia" },
            { id: 9, val: "Georgia" },
            { id: 2, val: "Russia" },
          ];
          this.reportForm.controls["warehouse"].setValue(7);
        } else if (d.warehouse[0].id === 3) {
          this.destinations = [{ id: 1, val: "Armenia" }];
          this.reportForm.controls["warehouse"].setValue(3);
        } else if (d.warehouse[0].id === 9) {
          this.destinations = [
            { id: 1, val: "Armenia" },
            { id: 9, val: "Georgia" },
            { id: 2, val: "Global" },
            { id: 10, val: "Kyrgyzstan" },
          ];
          this.reportForm.controls["warehouse"].setValue(9);
        } else if (d.warehouse[0].id === 10) {
          this.destinations = [{ id: 1, val: "Armenia" }];
          this.reportForm.controls["warehouse"].setValue(10);
        } else if (d.warehouse[0].id === 4) {
          this.destinations = [
            { id: "", val: "All" },
            { id: 1, val: "Armenia" },
            { id: 2, val: "Russia" },
            { id: 4, val: "Amanati" },
            { id: 5, val: "Ukraine" },
            { id: 6, val: "SpaceCargo" },
            { id: 9, val: "Georgia" },
            { id: 10, val: "Kyrgyzstan" },
            { id: 11, val: "PDD Armenia" },
            { id: 12, val: "PDD Georgia" },
          ];
          this.reportForm.controls["warehouse"].setValue(4);
        } else if (d.warehouse[0].id === 11) {
          this.destinations = [{ id: 10, val: "Kyrgyzstan" }];
          this.reportForm.controls["warehouse"].setValue(11);
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }
}
